<template>
  <div class="wizard-container">
    <form @submit.prevent>
      <!--        You can switch " data-color="primary" "
       with one of the next bright colors: "green", "orange", "red", "blue"       -->
      <md-card
        class="md-card-wizard active"
        data-color="green"
      >
        <md-card-header>
          <slot name="header">
            <h3 class="card-title">
              {{ title }}
            </h3>
            <h3 class="description">
              {{ subTitle }}
            </h3>
          </slot>
        </md-card-header>
        <div class="wizard-navigation">
          <ul
            class="nav nav-pills"
            role="tablist"
          >
            <li
              v-for="(tab, index) in tabs"
              :id="`step-${tab.tabId}`"
              :key="tab.title"
              :ref="`tab-${index}`"
              role="tab"
              :tabindex="tab.checked ? 0 : ''"
              :aria-controls="tab.tabId"
              :aria-disabled="tab.active"
              :aria-selected="tab.active"
              class="nav-item wizard-tab-link"
              :style="linkWidth"
            >
              <a
                class="nav-link"
                :class="[
                  { 'disabled-wizard-link': !tab.checked },
                  { active: tab.active },
                  { checked: tab.checked }
                ]"
                data-toggle="tab"
                @click="navigateToTab(index)"
              >
                <tab-item-content :tab="tab" />
              </a>
            </li>
          </ul>
          <div
            v-if="activeTab"
            class="moving-tab"
            :class="{ 'error-link': activeTab.hasError }"
            style="transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1); width: 100%;"
            :style="movingTabStyles"
          >
            <tab-item-content
              :tab="activeTab"
              :moving-tab="true"
            />
          </div>
        </div>

        <md-card-content>
          <div class="tab-content">
            <slot
              :activeIndex="activeTabIndex"
              :activeTab="activeTab"
            />
          </div>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <slot
            name="footer"
            :next-tab="nextTab"
            :prev-tab="prevTab"
          >
            <div>
              <md-button
                v-if="activeTabIndex > 0"
                class="btn-previous"
                @click.native="prevTab"
              >
                {{ prevButtonText }}
              </md-button>
              <md-button
                v-if="activeTabIndex === 0 && prevRoute"
                class="btn-previous"
                @click.native="goBack"
              >
                {{ cancelButtonText }}
              </md-button>
            </div>

            <div>
              <md-button
                v-if="activeTabIndex < tabCount - 1"
                class="btn-next md-success"
                @click.native="nextTab"
              >
                {{ nextButtonText }}
              </md-button>
              <md-button
                v-else-if="editable"
                class="md-success"
                @click.native="nextTab"
              >
                {{
                  finishButtonText
                }}
              </md-button>
            </div>
          </slot>
        </md-card-actions>
      </md-card>
    </form>
  </div>
</template>
<script>
import { throttle } from './throttle';

export default {
  name: 'SimpleWizard',
  components: {
    TabItemContent: {
      props: ['tab', 'movingTab'],
      render(h) {
        return h('span', [this.tab.$slots.label || this.tab.label]);
      },
    },
  },
  props: {
    startIndex: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: 'Title',
    },
    subTitle: {
      type: String,
      default: 'Subtitle',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    prevButtonText: {
      type: String,
      default: 'Previous',
    },
    nextButtonText: {
      type: String,
      default: 'Next',
    },
    finishButtonText: {
      type: String,
      default: 'Finish',
    },
    vertical: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    prevRoute: {
      type: [String, Object],
      default: null,
    },
    areTabsAvailable: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab,
    };
  },
  data() {
    return {
      tabs: [],
      activeTabIndex: 0,
      tabLinkWidth: 0,
      tabLinkHeight: 50,
    };
  },
  computed: {
    tabCount() {
      return this.tabs.length;
    },
    linkWidth() {
      let width = 100;
      if (this.tabCount > 0) {
        width = 100 / this.tabCount;
      }
      if (this.vertical) {
        width = 100;
      }
      return { width: `${width}%` };
    },
    activeTab() {
      return this.tabs[this.activeTabIndex];
    },
    movingTabStyles() {
      // eslint-disable-next-line no-nested-ternary
      let translateXValue = this.activeTabIndex === 0
        ? this.tabLinkWidth * this.activeTabIndex - 8
        : this.activeTabIndex === this.tabCount - 1
          ? this.tabLinkWidth * this.activeTabIndex + 8
          : this.tabLinkWidth * this.activeTabIndex;

      let translateYValue = 0;
      if (this.vertical) {
        translateYValue = this.tabLinkHeight * this.activeTabIndex;
        translateXValue = 0;
      }
      let styles = {};
      if (this.tabs.length !== 1) {
        styles = {
          transform: `translate3d(${translateXValue}px, ${translateYValue}px, 0px)`,
        };
      }
      if (this.tabLinkWidth !== 0) {
        styles.width = `${this.tabLinkWidth}px`;
      }
      return styles;
    },
  },
  watch: {
    activeTabIndex(newValue, oldValue) {
      if (newValue !== oldValue) {
        const oldTab = this.tabs[oldValue];
        const newTab = this.tabs[newValue];
        oldTab.active = false;
        newTab.active = true;

        if (!newTab.checked) {
          newTab.checked = true;
        }
        this.$emit('tab-change', oldTab, newTab);
        this.$emit('update:startIndex', newValue);
      }
    },
  },
  mounted() {
    this.activeTabIndex = this.startIndex;
    this.$nextTick(() => {
      this.tabs[this.activeTabIndex].active = true;
      this.tabs[this.activeTabIndex].checked = true;
      this.onResize();
    });

    window.addEventListener(
      'resize',
      () => {
        throttle(this.onResize, 40);
      },
      false,
    );
  },
  methods: {
    addTab(tab) {
      const index = this.$slots.default.indexOf(tab.$vnode);
      const tabTitle = tab.title || '';
      // eslint-disable-next-line no-param-reassign
      tab.tabId = `${tabTitle.replace(/ /g, '')}${index}`;
      if (!this.activeTab && index === 0) {
        // eslint-disable-next-line no-param-reassign
        tab.active = true;
        // eslint-disable-next-line no-param-reassign
        tab.checked = true;
      }
      if (this.activeTab === tab.name) {
        // eslint-disable-next-line no-param-reassign
        tab.active = true;
        // eslint-disable-next-line no-param-reassign
        tab.checked = true;
      }
      this.tabs.splice(index, 0, tab);

      if (this.areTabsAvailable) {
        this.allTabsAvailable();
      }
    },
    removeTab(tab) {
      const { tabs } = this;
      const index = tabs.indexOf(tab);
      if (index > -1) {
        tabs.splice(index, 1);
      }
    },
    validate(tab) {
      const tabToValidate = tab || this.activeTab;
      const { beforeChange } = tabToValidate;
      if (beforeChange) {
        return Promise.resolve(beforeChange())
          .then((res) => {
            this.activeTab.hasError = !res;
            return res;
          })
          .catch(() => {
            this.activeTab.hasError = true;
          });
      }
      return Promise.resolve(true);
    },
    async nextTab() {
      const mainPanel = document.querySelector('.main-panel');
      if (mainPanel) mainPanel.scrollIntoView();

      const isValid = await this.validate();
      if (isValid && this.activeTabIndex < this.tabCount - 1) {
        this.activeTabIndex += 1;
      }
      return isValid;
    },
    prevTab() {
      this.activeTabIndex -= 1;
    },
    async navigateToTab(index) {
      if (this.tabs[index].checked) {
        // recursively validate each tab
        if (index > this.activeTabIndex) {
          const valid = await this.nextTab();
          if (valid) {
            this.navigateToTab(index);
          }
        } else {
          this.activeTabIndex = index;
        }
      }
    },
    onResize() {
      const tabLinks = document.getElementsByClassName('wizard-tab-link');
      if (tabLinks.length > 0 && this.tabCount > 0) {
        const { clientWidth, clientHeight } = tabLinks[0];
        this.tabLinkWidth = clientWidth;
        this.tabLinkHeight = clientHeight;
      }
    },
    goBack() {
      this.$router.push(this.prevRoute);
    },
    allTabsAvailable() {
      this.tabs.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.checked = true;
      });
    },
  },
};
</script>
<style lang="scss">
  /* Tab content animation */
  .tab-content {
    display: flex; // to avoid horizontal scroll when animating
    .tab-pane {
      display: block;
      animation: fadeIn 0.5s;
      width: 100%;
    }
  }

  /**
      Extra niceties. Display error tabs and disable navigation unvisited tabs
     */
  .wizard-navigation .nav-link {
    &.active,
    &.checked {
      cursor: pointer;
    }
  }

  .disabled-wizard-link {
    cursor: not-allowed;
  }
</style>
